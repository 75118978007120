.resources {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .resources h1 {
    color: #1a237e;
    text-align: center;
  }
  
  .resources ul {
    list-style-type: none;
    padding: 0;
  }
  
  .resources li {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .resources h3 {
    margin-top: 0;
  }
  
  .resources a {
    color: #4a148c;
    text-decoration: none;
  }
  
  .resources a:hover {
    text-decoration: underline;
  }
  
  .big-o-preview-button {
    background-color: #ff4081;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    display: block;
  }
  
  .big-o-preview-button:hover {
    background-color: #f50057;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  
  .back-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .back-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #45a049;
  }