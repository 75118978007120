.how-to-play {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  h1 {
    text-align: center;
    color: #1a237e;
    font-size: 36px;
    margin-bottom: 30px;
  }
  
  .steps-container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .step {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .step:last-child {
    border-bottom: none;
  }
  
  .step:hover {
    background-color: #f0f0f0;
  }
  
  .step.active {
    background-color: #e8eaf6;
  }
  
  .step-icon {
    font-size: 24px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    background-color: #4a148c;
    color: white;
    border-radius: 50%;
  }
  
  .step-content h3 {
    margin: 0 0 10px 0;
    color: #1a237e;
  }
  
  .step-content p {
    margin: 0;
    color: #333;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .navigation button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .navigation button:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .navigation button:disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
  }
  
  .tips {
    margin-top: 40px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .tips h2 {
    color: #1a237e;
    margin-top: 0;
  }
  
  .tips ul {
    list-style-type: none;
    padding: 0;
  }
  
  .tips li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
  }
  
  .tips li::before {
    content: '👉';
    position: absolute;
    left: 0;
  }
  
  .back-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #1a237e;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #3949ab;
  }

/* Responsive design */
@media (max-width: 768px) {
  .how-to-play {
    padding: 20px 10px;
  }

  h1 {
    font-size: 28px;
  }

  .step {
    flex-direction: column;
  }

  .step-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .navigation {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .navigation button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 24px;
  }

  .step-content h3 {
    font-size: 18px;
  }

  .step-content p {
    font-size: 14px;
  }

  .tips h2 {
    font-size: 20px;
  }

  .tips li {
    font-size: 14px;
  }

  .back-button {
    width: 100%;
    text-align: center;
  }
}