.youtube-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.youtube-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  animation: scaleIn 0.3s ease-out;
}

.youtube-popup-close {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: #ff4081;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.2s;
}

.youtube-popup-close:hover {
  transform: scale(1.1);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}
