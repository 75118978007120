.main-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a237e, #4a148c);
    font-family: 'Arial', sans-serif;
  }
  
  .menu-content {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
  }
  
  .logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 36px;
    color: #1a237e;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .subtitle {
    font-size: 18px;
    color: #4a148c;
    margin-bottom: 30px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .menu-button {
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-block;
  }
  
  .menu-button.primary {
    background-color: #4caf50;
    color: white;
  }
  
  .menu-button.primary:hover {
    background-color: #45a049;
  }
  
  .menu-button.secondary {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .menu-button.secondary:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
  
  .menu-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .coming-soon {
    font-size: 14px;
    background-color: #ff9800;
    color: white;
    padding: 2px 6px;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
  }
  
  .footer-link {
    color: #1a237e;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s ease;
    flex: 1;
    text-align: center;
  }
  
  .footer-link:hover {
    color: #4a148c;
    text-decoration: underline;
  }
  
  .menu-button.resources {
    background-color: #ffa500; /* Orange color to make it stand out */
    color: white;
    margin-top: 20px; /* Add some space above the resources button */
  }
  
  .menu-button.resources:hover {
    background-color: #ff8c00; /* Darker orange on hover */
  }