.slow-round {
    display: flex;
    height: 100vh;
    background-color: #f0f0f0;
}

.code-panel, .input-panel {
    width: 50%;
    padding: 20px;
    overflow: auto;
}

.code-panel {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-panel {
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-button, .exit-button, .retry-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    margin-bottom: 20px;
}

.submit-button:hover {
    background-color: #45a049;
}

.footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score {
    font-size: 18px;
    font-weight: bold;
}

.exit-button {
    background-color: #f44336;
    color: white;
}

.exit-button:hover {
    background-color: #d32f2f;
}

.loading, .error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.error p {
    color: #f44336;
    margin-bottom: 20px;
}

.retry-button {
    background-color: #2196f3;
    color: white;
}

.retry-button:hover {
    background-color: #1e88e5;
}

/* Responsive design */
@media (max-width: 768px) {
    .slow-round {
        flex-direction: column;
        height: auto;
    }

    .code-panel, .input-panel {
        width: 100%;
        height: auto;
    }

    .code-panel {
        max-height: 50vh;
    }

    h2 {
        font-size: 20px;
    }

    input, .submit-button, .exit-button {
        font-size: 14px;
    }

    .footer {
        flex-direction: column;
        align-items: stretch;
        gap: 10px;
    }

    .score {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .code-panel, .input-panel {
        padding: 10px;
    }

    h2 {
        font-size: 18px;
    }

    input, .submit-button, .exit-button {
        font-size: 12px;
    }
}