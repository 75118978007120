.game-over {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a237e, #4a148c);
    font-family: 'Arial', sans-serif;
    color: #ffffff;
  }
  
  .game-over__content {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    width: 90%;
  }
  
  .game-over__title {
    font-size: 48px;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .game-over__score-display {
    margin-bottom: 30px;
  }
  
  .game-over__score-label {
    font-size: 24px;
    display: block;
    margin-bottom: 10px;
  }
  
  .game-over__score-value {
    font-size: 72px;
    font-weight: bold;
    color: #4caf50;
    text-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  }
  
  .game-over__form {
    margin-bottom: 30px;
  }
  
  .game-over__input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    font-size: 16px;
  }
  
  .game-over__input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .game-over__submit-btn,
  .game-over__btn {
    padding: 12px 24px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .game-over__submit-btn:hover,
  .game-over__btn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .game-over__submission-success {
    margin-bottom: 30px;
  }
  
  .game-over__leaderboard-position {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .game-over__leaderboard-position span {
    font-size: 24px;
    font-weight: bold;
    color: #ffd700;
  }
  
  .game-over__button-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .game-over__btn--leaderboard {
    background-color: #2196f3;
  }
  
  .game-over__btn--leaderboard:hover {
    background-color: #1e88e5;
  }
  
  .game-over__btn--menu {
    background-color: #f44336;
  }
  
  .game-over__btn--menu:hover {
    background-color: #d32f2f;
  }